<template>
  <div>
    <div class="wrapper section-blue">
      <!-- section dashboard -->
      <section
        id="section-support"
        class="section section-blue jpadding jpadding-20 section-unfixed"
      >
        <a href="https://talkii.app/userguide/en" target="_blank" class="jcard jcard-dash jcard-userguide">
          <h4><i class="fad fa-book"></i></h4>
          <p class="p-0 m-0">Talkii User Guide</p>
          <h4>English</h4>
        </a>

        <a href="https://talkii.app/userguide/fr" target="_blank" class="jcard jcard-dash jcard-userguide">
          <h4><i class="fad fa-book"></i></h4>
          <p class="p-0 m-0">Talkii User Guide</p>
          <h4>French</h4>
        </a>

        <a
          href="https://talkii.app/userguide/de"
          target="_blank"
          class="jcard jcard-dash jcard-userguide"
        >
          <h4><i class="fad fa-book"></i></h4>
          <p class="p-0 m-0">Talkii User Guide</p>
          <h4>German</h4>
        </a>

        <div
          class="jcard jcard-dash jcard-userguide"
        >
          <h4><i class="fad fa-book"></i></h4>
          <p class="p-0 m-0">Talkii User Guide</p>
          <h4>Italian</h4>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
//import firebase from "firebase/app";
// import C from "@/constants";
import PLANS from "@/plans";

export default {
  name: "UserGuide",
  data() {
    return {
      subStatusList: PLANS.STATUS_INDEX,
      subTypeList: PLANS.TYPE_INDEX,
      firstname: "",
      lastname: "",
      homeTitle: "Bonjour ",

      //userId: firebase.auth().currentUser.uid,
    };
  },
};
</script>

<style scoped>
.jcard-userguide {
  display: grid;
  justify-items: center;
  align-content: center;
  text-align: center;
}
</style>
